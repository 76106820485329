export function getEnvironmentVariablesHandler() {
  let result = {};
  if (window.location.hostname.includes('mytuiprofile.tui-dx.com')) {
    result = {
      apiBaseUrl: 'https://tui-prod-prod.apigee.net/corporate/employee/placement/api/',
      tableauWebUrl: 'https://gpx-tableau.pre.tui-dx.com',
      environment: 'prod',
    };
  } else if (window.location.hostname.includes('mytuiprofile.pre.tui-dx.com')) {
    result = {
      apiBaseUrl: 'https://tui-nonprod-pre-prod.apigee.net/corporate/employee/placement/api/',
      tableauWebUrl: 'https://gpx-tableau.pre.tui-dx.com',
      environment: 'pre',
    };
  } else if (window.location.hostname.includes('mytuiprofile.test.tui-dx.com')) {
    result = {
      apiBaseUrl: 'https://tui-nonprod-test.apigee.net/corporate/employee/placement/api/',
      tableauWebUrl: 'https://gpx-tableau.test.tui-dx.com',
      environment: 'test',
    };
  } else if (window.location.hostname.includes('localhost')) {
    result = {
      apiBaseUrl: 'http://localhost:5000/api/',
      tableauWebUrl: 'http://localhost:3006',
      environment: 'localhost',
    };
  } else {
    result = {
      apiBaseUrl: 'unknown',
      tableauWebUrl: 'unknown',
      environment: 'unknown',
    };
  }
  result.version = '2024.0926.105914';
  return result;
}
